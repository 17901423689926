<template>
  <ToUser />
  <!-- 等待審核中start -->
  <!-- <div class=" flex justify-center items-center my-40">
    <div class=" items-center">
      <div class=" text-3xl mb-10 font-bold">等待審核中</div>
      <div class=" text-base">我們正在為您審核供應商資料，審核通過會直接開通頁面；<br>您可以設定帳號相關資訊與建立商品資料。</div>
    </div>
  </div> -->
  <!-- 等待審核中end -->
  <div v-if="!editMode">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">基本資料閱覽與管理</h2>
      <div v-if="auth?.isBuyer" class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          type="button"
          @click="showEdit(true)"
          class="button text-white bg-theme-1 shadow-md mr-2 inline-flex items-center"
        >
          <Edit2Icon class="mr-2 w-4 h-4" />
          編輯
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-600 mb-10">帳號資料</div>
      <div class=" lg:grid grid-cols-2 gap-5">
        <div class=" border-0 lg:border-r border-theme-5 pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">帳號</div>
            <div class="  text-base text-theme-1">
              {{ info?.account }}
              <a
                v-if="info?.account"
                href="#"
                class="verify-tag ml-3 bg-theme-1 text-white pointer-events-none px-3 py-1 rounded-sm"
                >已驗證</a
              >
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">身份</div>
            <div class="  text-base text-theme-1">{{ info.identity }}</div>
          </div>
        </div>
        <div class="pl-5" v-if="!auth?.isBuyer">
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#upgrade-modal"
            class="button text-white bg-theme-1 shadow-md mr-2  inline-flex items-center"
          >
            <ArrowUpIcon class="w-4 h-4 mr-2" />
            升級買家帳號
          </a>
          <div class="mt-5 text-base text-gray-600">
            完成實名制，直接與賣家互動<br />完整享受平台服務，透過方便的即時商洽系統，針對您感興趣的產品快速進行詢問。
          </div>
        </div>
      </div>
    </div>

    <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-600 mb-10">個人資料</div>
      <div class=" lg:grid grid-cols-2 ">
        <div class=" pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">真實姓名</div>
            <div class="  text-base">{{ info?.name }}</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">暱稱</div>
            <div class="  text-base">
              {{ info?.displayName === "null" ? "" : info?.displayName }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">性別</div>
            <div class="  text-base">
              {{ info?.sex ? (info.sex == "M" ? "男性" : "女性") : "" }}
            </div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">行動電話</div>
            <div class="  text-base">{{ info?.mobile }}</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Email</div>
            <div class="  text-base">{{ info?.email }}</div>
          </div>
        </div>
        <div class="lg:pl-5">
          <div class=" sm:flex mb-8">
            <div class="sm:w-20 sm:text-right text-base mr-5">頭像</div>
            <div class="w-9.375 h-9.375 relative">
              <img
                v-if="info?.avatarImageID"
                class=" max-w-full h-auto"
                :src="`${imageUrl}/${info?.avatarImageID}`"
                style=" width:150px"
                alt="個人頭像"
              />
              <img
                v-else
                class=" max-w-full h-auto"
                src="@/midone/assets/images/placeholders/200x200.jpg"
                style=" width:150px"
                alt="個人頭像"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="intro-y box p-10 mt-10" v-if="info?.isVendor">
      <div class=" text-lg text-gray-600 mb-10">供應商資料</div>
      <div class=" lg:grid grid-cols-2 ">
        <div class=" pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">所屬供應商</div>
            <div class=" text-base">{{ vendor?.name }}</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">職稱</div>
            <div class=" text-base">{{ info?.contact_Title }}</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">業務信箱</div>
            <div class=" text-base">{{ info?.contact_Email }}</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">業務電話</div>
            <div class=" text-base">{{ info?.contact_Tel }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-600 mb-10">社群帳號連結</div>
      <div class="">
        <div class=" pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Google</div>
            <div class="  text-base">未連結</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Line</div>
            <div class="  text-base">未連結</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Feacebook</div>
            <div class="  text-base">未連結</div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-600 mb-10">社群媒體推播通知設定</div>
      <div class="">
        <div class=" pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Google</div>
            <div class="  text-base">未連結</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Line</div>
            <div class="  text-base">未連結</div>
          </div>
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">Feacebook</div>
            <div class="  text-base">未連結</div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="intro-y box p-10 mt-10">
      <div class=" text-lg text-gray-600 mb-10">帳號停用與刪除</div>
      <div class="">
        <div class=" pr-5">
          <div class=" sm:flex mb-8">
            <div class=" sm:w-20 sm:text-right text-base mr-5">帳號刪除</div>
            <div class="  text-base">無</div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- END: HTML Table Data -->
  </div>

  <!-- 升級帳號modal -->
  <div
    id="upgrade-modal"
    class="modal flex justify-center items-center pointer-events-none"
  >
    <div
      class="modal__content modal__content--xl max-h-screen overflow-y-scroll sm:overflow-y-visible relative w-screen p-6 max-w-screen-lg pointer-events-auto"
    >
      <a
        data-dismiss="modal"
        ref="modalClose"
        href="javascript:;"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a>
      <div class="">
        <div id="step1" class="">
          <VForm
            @submit="buyerModal.updateBuyer"
            :initial-values="buyerModal.formData"
            :validation-schema="upgradeSchema"
          >
            <div class="font-bold text-2xl xl:text-3xl my-3">
              買家帳號資料設定
            </div>
            <div class=" text-gray-600">
              為保障您的權益，請確實填寫下列資料。
            </div>
            <div class="py-5 lg:grid lg:grid-cols-2 gap-4 px-0">
              <div class="mb-5 lg:mb-0">
                <div>
                  <label class=" font-medium text-lg"
                    >真實姓名<span class=" text-red-400">＊</span></label
                  >
                  <div class="mt-2">
                    <CfInputBox
                      name="name"
                      placeholder="真實姓名"
                      type="text"
                      v-model="buyerModal.formData.name"
                    ></CfInputBox>
                  </div>
                  <!-- <input
                    type="text"
                    v-model="name"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                    placeholder="真實姓名"
                  /> -->
                </div>
                <div v-show="!info?.isMobileVerified">
                  <label class=" font-medium text-lg"
                    >行動電話<span class=" text-red-400">＊</span></label
                  >
                  <div class="mt-2">
                    <CfInputBox
                      name="mobile"
                      placeholder="行動電話"
                      type="text"
                      v-model="buyerModal.formData.mobile"
                    ></CfInputBox>
                  </div>
                  <!-- <input
                    type="text"
                    v-model="mobile"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                    placeholder="行動電話"
                  /> -->
                </div>
                <div v-show="!info?.isEmailVerified">
                  <label class=" font-medium text-lg"
                    >電子郵件<span class=" text-red-400">＊</span></label
                  >
                  <div class="mt-2">
                    <CfInputBox
                      name="email"
                      placeholder="電子郵件"
                      type="text"
                      v-model="buyerModal.formData.email"
                    ></CfInputBox>
                  </div>
                  <!-- <input
                    type="text"
                    v-model="email"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                    placeholder="電子郵件"
                  /> -->
                </div>
              </div>
              <div class="">
                <div>
                  <label class=" font-medium text-lg"
                    >性別<span class=" text-red-400">＊</span></label
                  >
                  <div class="">
                    <CfSelectBox
                      name="sex"
                      placeholder="性別"
                      :items="gender"
                      v-model="buyerModal.formData.sex"
                    ></CfSelectBox>

                    <!-- <select
                      v-model="sex"
                      class="intro-x input input--lg border border-gray-300 w-full"
                    >
                      <option
                        v-for="item in gender"
                        :key="item.value"
                        :value="item.value"
                        >{{ item.text }}</option
                      >
                    </select> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-end pb-5">
              <button
                type="submit"
                class="button  button--lg w-full lg:w-32 text-white bg-theme-1 align-top mb-2 lg:mb-0"
              >
                升級帳號
              </button>
            </div>
          </VForm>
        </div>

        <div id="step3" class="hidden">
          <div class="font-bold text-2xl xl:text-3xl my-3">完成升級</div>
          <div class="py-12 px-0">
            <div class=" text-base">
              感謝您升級成買家帳號，系統將於五秒後登出，請重新登入！<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <UserEdit v-if="editMode" @showEdit="showEdit" />
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ToUser from "@/components/user-modal/Main.vue";
import UserEdit from "@/views/user-edit/Main.vue";
import router from "@/router";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfSelectBox from "@/cloudfun/components/CfSelectBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  components: {
    // Grid,
    // SelectBox,
    // FileUploader,
    ToUser,
    UserEdit,
    CfInputBox,
    CfSelectBox,
    VForm: Form
  },
  setup() {
    const model = CloudFun.current?.model;
    const user = ref<any>({});
    const info = ref<any>({});
    const auth = ref<any>({});
    const account = computed(() =>
      user.value?.isMobileVerified && user.value?.isEmailVerified
        ? `${user.value.email}/${user.value.mobile}`
        : user.value?.isMobileVerified
        ? user.value.mobile
        : user.value.email
    );
    const startCountDown = ref(false);
    const accountType = computed(() =>
      buyerModal.formData.email ? "email" : "phone"
    );

    const gender = ref<any>([]);
    const verificationCode = ref("");
    const vendor = ref<any>({});

    const upgradeSchema = Yup.object().shape({
      name: Yup.string().required("必要欄位"),
      mobile: Yup.string().required("必要欄位"),
      sex: Yup.string().required("必要欄位"),
      email: Yup.string()
        .email("Email格式錯誤")
        .required("必要欄位")
    });

    const getUser = () => {
      return model
        ?.dispatch("user/buyer")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendorInfo = () => {
      return model
        ?.dispatch("vendor/info")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const upgradeBuyer = (data: any) => {
      console.log("updateBuyer", data);
    };

    const buyerModal = reactive({
      formData: { name: "", sex: "", mobile: "", email: "" },
      async updateBuyer(data: any) {
        const form = buyerModal.formData;

        await model
          ?.dispatch("user/updateBuyer", {
            name: form.name,
            sex: form.sex,
            mobile: user.value?.isMobileVerified
              ? user.value?.mobile
              : form.mobile,
            email: user.value?.isEmailVerified ? user.value?.email : form.email
          })
          .then(response => {
            if (response.status) {
              cash("#step3").removeClass("hidden");
              cash("#step1").addClass("hidden");
              setTimeout(() => {
                cash("#step3").addClass("hidden");
                modalClose.value?.click();
                localStorage.setItem("upgrade", info.value?.account);
                sessionStorage.clear();
                router.push("/login");
              }, 5000);
            } else {
              CloudFun.send("error", {
                subject: "升級失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "升級失敗",
              content: error.message
            });
          });
      },
      async resendVerify() {
        doCountDown();
        // await model
        //   ?.dispatch("system/resend", {
        //     type: buyerModal.formData.email ? "Email" : "Mobile",
        //     resendKey: "",
        //   })
        //   .then(response => {
        //     console.log(response);
        //     startCountDown.value = response.status;
        //     if (startCountDown.value) {
        //       doCountDown();
        //     } else {
        //       CloudFun.send(response.status ? "info" : "error", {
        //         subject: response.status ? "發送成功" : "發送失敗",
        //         content: response.status ? "發送失敗" : response.message
        //       });
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error.message);
        //     CloudFun.send("error", {
        //       subject: "驗證碼發送失敗",
        //       content: error.message
        //     });
        //   });
      },
      async sendVerify() {
        if (!verificationCode.value) {
          CloudFun.send("error", {
            subject: "驗證失敗",
            content: "請輸入驗證碼"
          });
          return;
        }
        await model
          ?.dispatch(
            buyerModal.formData.email
              ? "user/verifyEmail"
              : "user/verifyMobile",
            {
              code: verificationCode.value
            }
          )
          .then(response => {
            if (response.status) {
            } else {
              CloudFun.send(response.status ? "info" : "error", {
                subject: response.status ? "註冊成功" : "註冊失敗",
                content: response.status ? "註冊成功" : response.message
              });
            }
          })
          .catch(error => {
            console.log(error.message);
            CloudFun.send("error", {
              subject: "驗證失敗",
              content: error.message
            });
          });
      },
      reset() {
        buyerModal.formData = { name: "", sex: "", mobile: "", email: "" };
      },

      formValid() {
        return true;
      }
    });

    const init = async () => {
      user.value = await getUser();
      auth.value = await getAuth();
      info.value = await getInfo();
      if (info.isVendor) {
        vendor.value = await getVendorInfo();
      }

      buyerModal.formData = {
        name: info.value.name,
        sex: info.value.sex,
        mobile: info.value.mobile,
        email: info.value.email
      };

      console.log("buyerModal.formData", buyerModal.formData);
    };

    onMounted(async () => {
      await init();
      var genderData = await model?.dispatch("option/find", ["Gender"]);
      if (genderData) gender.value = genderData.Gender;
    });

    const alert = (type: string, subject: string, content: string) => {
      CloudFun.send(type, {
        subject: subject,
        content: content
      });
    };

    const modalClose = ref<any>();
    const submitText = ref<string>("重新傳送驗證碼");
    const countDownValue = 10;
    const countDown = ref(countDownValue);

    const doCountDown = async function() {
      startCountDown.value = true;
      countDown.value = countDownValue;
      const timer = setInterval(() => {
        if (countDown.value > 0 && startCountDown.value) {
          countDown.value -= 1;
          console.log("countDown", countDown.value);
          if (countDown.value == 0) startCountDown.value = false;
        }
      }, 1000);
      if (!startCountDown.value) {
        countDown.value = countDownValue;
        clearInterval(timer);
      }
    };

    const editMode = ref(false);
    const showEdit = async (val: boolean) => {
      if (val == false) await init();
      editMode.value = val;
    };

    return {
      user,
      account,
      buyerModal,
      gender,
      accountType,
      verificationCode,
      modalClose,
      startCountDown,
      submitText,
      countDown,
      info,
      auth,
      imageUrl: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      editMode,
      showEdit,
      upgradeSchema,
      upgradeBuyer,
      vendor
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    toStep2() {}
  }
});
</script>
