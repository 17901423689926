
/* eslint-disable */
import CloudFun, {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ToUser from "@/components/user-modal/Main.vue";
import UserEdit from "@/views/user-edit/Main.vue";
import router from "@/router";
import CfInputBox from "@/cloudfun/components/CfInputBoxHorizontal.vue";
import CfSelectBox from "@/cloudfun/components/CfSelectBox.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
export default defineComponent({
  components: {
    // Grid,
    // SelectBox,
    // FileUploader,
    ToUser,
    UserEdit,
    CfInputBox,
    CfSelectBox,
    VForm: Form
  },
  setup() {
    const model = CloudFun.current?.model;
    const user = ref<any>({});
    const info = ref<any>({});
    const auth = ref<any>({});
    const account = computed(() =>
      user.value?.isMobileVerified && user.value?.isEmailVerified
        ? `${user.value.email}/${user.value.mobile}`
        : user.value?.isMobileVerified
        ? user.value.mobile
        : user.value.email
    );
    const startCountDown = ref(false);
    const accountType = computed(() =>
      buyerModal.formData.email ? "email" : "phone"
    );

    const gender = ref<any>([]);
    const verificationCode = ref("");
    const vendor = ref<any>({});

    const upgradeSchema = Yup.object().shape({
      name: Yup.string().required("必要欄位"),
      mobile: Yup.string().required("必要欄位"),
      sex: Yup.string().required("必要欄位"),
      email: Yup.string()
        .email("Email格式錯誤")
        .required("必要欄位")
    });

    const getUser = () => {
      return model
        ?.dispatch("user/buyer")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getVendorInfo = () => {
      return model
        ?.dispatch("vendor/info")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const getInfo = () => {
      return model
        ?.dispatch("user/find")
        .then(response => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch(error => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const upgradeBuyer = (data: any) => {
      console.log("updateBuyer", data);
    };

    const buyerModal = reactive({
      formData: { name: "", sex: "", mobile: "", email: "" },
      async updateBuyer(data: any) {
        const form = buyerModal.formData;

        await model
          ?.dispatch("user/updateBuyer", {
            name: form.name,
            sex: form.sex,
            mobile: user.value?.isMobileVerified
              ? user.value?.mobile
              : form.mobile,
            email: user.value?.isEmailVerified ? user.value?.email : form.email
          })
          .then(response => {
            if (response.status) {
              cash("#step3").removeClass("hidden");
              cash("#step1").addClass("hidden");
              setTimeout(() => {
                cash("#step3").addClass("hidden");
                modalClose.value?.click();
                localStorage.setItem("upgrade", info.value?.account);
                sessionStorage.clear();
                router.push("/login");
              }, 5000);
            } else {
              CloudFun.send("error", {
                subject: "升級失敗",
                content: response.message
              });
            }
          })
          .catch(error => {
            CloudFun.send("error", {
              subject: "升級失敗",
              content: error.message
            });
          });
      },
      async resendVerify() {
        doCountDown();
        // await model
        //   ?.dispatch("system/resend", {
        //     type: buyerModal.formData.email ? "Email" : "Mobile",
        //     resendKey: "",
        //   })
        //   .then(response => {
        //     console.log(response);
        //     startCountDown.value = response.status;
        //     if (startCountDown.value) {
        //       doCountDown();
        //     } else {
        //       CloudFun.send(response.status ? "info" : "error", {
        //         subject: response.status ? "發送成功" : "發送失敗",
        //         content: response.status ? "發送失敗" : response.message
        //       });
        //     }
        //   })
        //   .catch(error => {
        //     console.log(error.message);
        //     CloudFun.send("error", {
        //       subject: "驗證碼發送失敗",
        //       content: error.message
        //     });
        //   });
      },
      async sendVerify() {
        if (!verificationCode.value) {
          CloudFun.send("error", {
            subject: "驗證失敗",
            content: "請輸入驗證碼"
          });
          return;
        }
        await model
          ?.dispatch(
            buyerModal.formData.email
              ? "user/verifyEmail"
              : "user/verifyMobile",
            {
              code: verificationCode.value
            }
          )
          .then(response => {
            if (response.status) {
            } else {
              CloudFun.send(response.status ? "info" : "error", {
                subject: response.status ? "註冊成功" : "註冊失敗",
                content: response.status ? "註冊成功" : response.message
              });
            }
          })
          .catch(error => {
            console.log(error.message);
            CloudFun.send("error", {
              subject: "驗證失敗",
              content: error.message
            });
          });
      },
      reset() {
        buyerModal.formData = { name: "", sex: "", mobile: "", email: "" };
      },

      formValid() {
        return true;
      }
    });

    const init = async () => {
      user.value = await getUser();
      auth.value = await getAuth();
      info.value = await getInfo();
      if (info.isVendor) {
        vendor.value = await getVendorInfo();
      }

      buyerModal.formData = {
        name: info.value.name,
        sex: info.value.sex,
        mobile: info.value.mobile,
        email: info.value.email
      };

      console.log("buyerModal.formData", buyerModal.formData);
    };

    onMounted(async () => {
      await init();
      var genderData = await model?.dispatch("option/find", ["Gender"]);
      if (genderData) gender.value = genderData.Gender;
    });

    const alert = (type: string, subject: string, content: string) => {
      CloudFun.send(type, {
        subject: subject,
        content: content
      });
    };

    const modalClose = ref<any>();
    const submitText = ref<string>("重新傳送驗證碼");
    const countDownValue = 10;
    const countDown = ref(countDownValue);

    const doCountDown = async function() {
      startCountDown.value = true;
      countDown.value = countDownValue;
      const timer = setInterval(() => {
        if (countDown.value > 0 && startCountDown.value) {
          countDown.value -= 1;
          console.log("countDown", countDown.value);
          if (countDown.value == 0) startCountDown.value = false;
        }
      }, 1000);
      if (!startCountDown.value) {
        countDown.value = countDownValue;
        clearInterval(timer);
      }
    };

    const editMode = ref(false);
    const showEdit = async (val: boolean) => {
      if (val == false) await init();
      editMode.value = val;
    };

    return {
      user,
      account,
      buyerModal,
      gender,
      accountType,
      verificationCode,
      modalClose,
      startCountDown,
      submitText,
      countDown,
      info,
      auth,
      imageUrl: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      editMode,
      showEdit,
      upgradeSchema,
      upgradeBuyer,
      vendor
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    toStep2() {}
  }
});
